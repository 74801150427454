<template>
    <el-form :model="data" ref="data" class="case-form">
        <div v-for="(config, i) in configs" :key="i" class="inner">
            <div class="config">
                <component
                    v-for="(render, j) in config"
                    :key="j"
                    v-model="data[render.fieldName]"
                    :is="render.component"
                    :depend="dependObject(render.depend)"
                    :label="render.label"
                    :config="render"
                    :mode="mode"
                    @change="(input) => handleChange(input, i, j)"
                >
                </component>
            </div>
        </div>
    </el-form>
</template>

<script>
import { mapState, mapMutations } from 'vuex';

export default {
    props: {
        mode: {
            type: String,
            default: '',
        },
        stored: {
            type: Object,
            default: () => {},
        },
    },
    computed: {
        ...mapState({
            configs: (state) => state.config.question,
        }),
    },
    destroyed() {
        this.setFormStoredState(true);
        this.setMode('EDIT');
    },
    data() {
        return {
            data: {},
        };
    },
    created() {
        if (this.stored) this.data = this.stored;
        this.setTarget(this.data);
    },
    mounted() {
        const FORM = this.$refs.data.validate;
        this.setFormValidateFunction(FORM);
    },
    methods: {
        ...mapMutations({
            setFormStoredState: 'form/setFormStoredState',
            setFormValidateFunction: 'form/setFormValidateFunction',
            setTarget: 'form/setTarget',
            setMode: 'form/setMode',
        }),
        dependObject(depend) {
            if (!depend) return undefined;
            const RETURN = {
                rule: depend.rule,
                fieldName: depend.fieldName,
                data: this.data[depend.fieldName],
            };
            return RETURN;
        },
        handleChange(val, i, j) {
            this.setFormStoredState(false);
            const FIELD_NAME = this.configs[i][j].fieldName;
            this.data[FIELD_NAME] = val;
            this.setTarget(this.data);
        },
    },
};
</script>

<style scoped lang="scss">
.case-form {
    width: 90%;
    margin: 0 auto;
    padding-top: 30px;
    box-sizing: border-box;
    > :first-child {
        margin-top: 0 !important;
    }
    > :last-child {
        margin-top: 0 !important;
        margin-bottom: 0 !important;
    }
    .config {
        width: 100%;
        padding: 0 30px;
        margin: 0 auto;
        box-sizing: border-box;
    }
    .inner {
        .config > :first-child {
            margin-top: 0 !important;
        }
        .config > :last-child {
            margin-bottom: 0 !important;
        }
        width: 100%;
        margin: 40px 0;
        padding: 30px 0;
        border-radius: 14px;
        box-sizing: border-box;
    }
}
</style>
